import Vue from 'vue'
import Home from './Home.vue'
import store from './store'
import VueWait from 'vue-wait'

import { saveTokens } from '@/api/http-utils'
import { addMarketingParamsToPageUrls } from '@/utilities/marketing'
import VueGeolocation from 'vue-browser-geolocation'
import PortalVue from 'portal-vue'
import get from 'lodash/get'

/**
 * sentry
 */
import * as Sentry from '@sentry/vue'
import { sentryFilter } from '@/data/sentry_ignore'
// import { BrowserTracing } from '@sentry/tracing'
import { setTREnvironment } from '@/utilities/buildEnvironmentValues'
setTREnvironment()
let lastTag = 'local'
let buildTime = (new Date()).toISOString()
if (typeof process.env.MIX_LAST_TAG !== 'undefined') {
  lastTag = process.env.MIX_LAST_TAG
}
if (typeof process.env.MIX_BUILD_TIME !== 'undefined') {
  buildTime = process.env.MIX_BUILD_TIME
}
console.warn(`Version: ${buildTime} (${lastTag})`)

const SENTRY_SAMPLE_RATE = (process.env.MIX_SENTRY_ENVIRONMENT !== 'production') ? 1 : 0.01
Sentry.init({
  Vue,
  // dsn: process.env.MIX_SENTRY_SEARCH_DSN,
  logErrors: true,
  // integrations: [
  //   new BrowserTracing({
  //     tracingOrigins: ['localhost', 'triconresidential.test', 'triconresidential.com', 'tricon.creativeoven.com', /^\//]
  //   })
  // ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: SENTRY_SAMPLE_RATE,
  sampleRate: 0.01,
  environment: process.env.MIX_SENTRY_ENVIRONMENT,
  release: lastTag,
  ignoreErrors: sentryFilter.ignoreErrors,
  denyUrls: sentryFilter.denyUrls
})
Vue.use(PortalVue)
saveTokens()
/**
 * this step is now handled by the code from the common marketing core on the main web page
 * so that even if pages end up having no VueJs code or it fails to load marketing is still
 * captured in a consistent way
storeMarketing()
 */

// todo:(scd) this may need to fire on a different event later due to the common marketing firing earlier...
window.addEventListener('load', function () {
  addMarketingParamsToPageUrls()
})

Vue.use(VueWait)

Vue.use(VueGeolocation)
// if you want to use custom breakpoints https://github.com/butaminas/better-vue-breakpoints

Vue.config.productionTip = false

/**
 * add a $isEUVisitor to components given header value provided by Pagely
 * @type {boolean}
 */
Vue.prototype.$isEUVisitor = get(window, '__INITIAL_SETTINGS__.location.is_eu', false)
console.log('Vue.prototype.$isEUVisitor', Vue.prototype.$isEUVisitor)

new Vue({
  store,

  wait: new VueWait({
    // Defaults values are following:
    useVuex: false, // Uses Vuex to manage wait state
    vuexModuleName: 'wait', // Vuex module name

    registerComponent: true, // Registers `v-wait` component
    componentName: 'v-wait', // <v-wait> component name, you can set `my-loader` etc.

    registerDirective: true, // Registers `v-wait` directive
    directiveName: 'wait' // <span v-wait /> directive name, you can set `my-loader` etc.

  }),
  render: h => h(Home)
}).$mount('#loadHome')
